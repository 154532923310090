// components/UserLeave/LeaveSummary.jsx
import React from 'react';
import { useLeaveSummary } from '../../hooks/useLeaveSummary';
import Skeleton from '../common/Skeleton/Skeleton';
import LeaveBalanceBar from './LeaveBalanceBar';
import { format } from 'date-fns';
import tr from 'date-fns/locale/tr';

const SummaryCard = ({ title, value }) => (
    <div className="col-md-4 mb-4">
        <div className="card h-100">
            <div className="card-body">
                <h6 className="card-title">{title}</h6>
                <p className="card-text">{value}</p>
            </div>
        </div>
    </div>
);

const formatDate = (dateString) => {
    if (!dateString) return '';
    return format(new Date(dateString), 'd MMMM yyyy', { locale: tr });
};

const LeaveSummary = ({ userId, selectedLeaveType, selectedFinYear }) => {
    const { summary, loading, error } = useLeaveSummary(userId, selectedLeaveType, selectedFinYear);

    if (loading) {
        return <Skeleton count={12} />;
    }

    if (error) return <div className="alert alert-danger">{error}</div>;
    if (!summary) return null;
    if (!selectedLeaveType) {
        return (
            <div className="alert alert-warning">
                Lütfen özet bilgilerini görmek için bir izin türü seçin.
            </div>
        );
    }

    const usableLeaveBalance = summary.total_earned_leave - summary.total_used_leave - summary.future_approved_leaves;
    return (
        <div className="leave-summary">
            <div className="row mb-4">
                <SummaryCard title="İzne Esas Hak Ediş Tarihi" value={formatDate(summary.leave_start_date)} />
                <SummaryCard title="Sonraki İzin Hak Ediş Tarihi" value={formatDate(summary.next_leave_entitlement_date)} />
                <SummaryCard title="İzne Esas Kıdem" value={`${summary.seniority} yıl`} />
            </div>

            <div className="row mb-4">
                <div className="col-12">
                    <LeaveBalanceBar
                        usableLeaveBalance={usableLeaveBalance}
                        totalEarnedLeave={summary.total_earned_leave}
                        totalEarnedLeaveText={summary.total_earned_leave_text}
                        totalUsedLeave={summary.total_used_leave}
                        futureApprovedLeaves={summary.future_approved_leaves}
                        startDate={summary.leave_start_date}
                        endDate={summary.next_leave_entitlement_date}
                    />
                </div>
            </div>

            <div className="row mb-4">
                <SummaryCard title="Toplam Hak Edilen" value={summary.total_balance_minutes_text} />
                <SummaryCard title="Toplam Kullanılan" value={summary.total_used_leave_text} />
                <SummaryCard title="Dönemdeki İleri Tarihli Onaylanmış İzinler" value={`${summary.future_approved_leaves} gün`} />
            </div>

            <div className="row mb-4">
                <SummaryCard title="Son Dönemde Hak Edilen" value={`${summary.last_period_earned_leave} gün`} />
                <SummaryCard title="Dönemde Kullanılan" value={`${summary.period_used_leave} gün`} />
                <SummaryCard title="Sonraki Dönem Onaylanmış İzinler" value={`${summary.next_period_approved_leaves} gün`} />
            </div>

            <div className="row">
                <SummaryCard title="Toplam Devreden" value={`${summary.total_carried_over_leave} gün`} />
                <SummaryCard title="Kullanılabilir Devreden" value={`${summary.usable_carried_over_leave} gün`} />
                <SummaryCard title="Devir Kayıpları" value={`${summary.carried_over_leave_losses} gün`} />
            </div>
        </div>
    );
};

export default LeaveSummary;