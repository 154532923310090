// components/UserLeave/LeaveBalanceBar.jsx
import React from 'react';
import { format } from 'date-fns';
import tr from 'date-fns/locale/tr';

const LeaveBalanceBar = ({
                             usableLeaveBalance,
                             totalEarnedLeave,
                             totalEarnedLeaveText,
                             totalUsedLeave,
                             futureApprovedLeaves,
                             startDate,
                             endDate
                         }) => {
    const usablePercentage = (usableLeaveBalance / totalEarnedLeave) * 100;
    const usedPercentage = (totalUsedLeave / totalEarnedLeave) * 100;
    const futurePercentage = (futureApprovedLeaves / totalEarnedLeave) * 100;

    const formatDate = (dateString) => {
        if (!dateString) return '';
        return format(new Date(dateString), 'd MMM yyyy', { locale: tr });
    };

    return (
        <div className="leave-balance-bar">
            <h6>Kullanılabilir İzin Bakiyesi</h6>
            <div className="progress">
                <div
                    className="progress-bar bg-success"
                    style={{width: `${usablePercentage}%`}}
                    role="progressbar"
                    aria-valuenow={usableLeaveBalance}
                    aria-valuemin="0"
                    aria-valuemax={totalEarnedLeave}
                ></div>
                <div
                    className="progress-bar bg-warning"
                    style={{width: `${futurePercentage}%`}}
                    role="progressbar"
                    aria-valuenow={futureApprovedLeaves}
                    aria-valuemin="0"
                    aria-valuemax={totalEarnedLeave}
                ></div>
                <div
                    className="progress-bar bg-danger"
                    style={{width: `${usedPercentage}%`}}
                    role="progressbar"
                    aria-valuenow={totalUsedLeave}
                    aria-valuemin="0"
                    aria-valuemax={totalEarnedLeave}
                ></div>
            </div>
            <p>{totalEarnedLeaveText}</p>
            <p className="text-muted">
                Güncel Hakediş Dönemi: {formatDate(startDate)} - {formatDate(endDate)}
            </p>
        </div>
    );
};

export default LeaveBalanceBar;