import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["select", "sc430", "makim", "shared", "nfcqr"];

    connect() {
        this.selected();
    }

    selected() {
        this.hideFields();
        const selectedDeviceType = this.selectTarget.value;

        switch (selectedDeviceType) {
            case "sc430":
                this.showField("sc430");
                break;
            case "makim_v1":
            case "makim_v2":
            case "makim_qr":
                this.showField("makim");
                break;
            case "passgage":
            case "passgage_qr":
            case "nfc":
                this.showField("shared");
                break;
            case "nfc_qr":
                this.showField("shared");
                this.showField("nfcqr");
                break;
            default:
                console.warn(`Unknown device type: ${selectedDeviceType}`);
                break;
        }
    }

    hideFields() {
        ["sc430", "makim", "shared", "nfcqr"].forEach(targetName => {
            try {
                const target = this[`${targetName}Target`];
                if (target) {
                    target.classList.add("d-none");
                    this.disableInputs(target);
                }
            } catch (error) {
                console.warn(`Target ${targetName} not found`);
            }
        });
    }

    showField(targetName) {
        try {
            const target = this[`${targetName}Target`];
            if (target) {
                target.classList.remove("d-none");
                this.enableInputs(target);
            }
        } catch (error) {
            console.warn(`Target ${targetName} not found`);
        }
    }

    disableInputs(target) {
        target.querySelectorAll("input, textarea").forEach((input) => {
            input.disabled = true;
        });
    }

    enableInputs(target) {
        target.querySelectorAll("input, textarea").forEach((input) => {
            input.disabled = false;
        });
    }
}