import React, { useState, useEffect } from 'react';
import { formatMinutesToHours } from '../../utilities/timeUtils';
import { createPayment } from '../../services/compensatoryTimeApi';
import { useCompensatoryBalance } from '../../hooks/useCompensatoryBalance';
import { useCompensatoryContext } from '../../context/CompensatoryContext';

const CompensatoryBalancePayment = ({ userId, timesheetId, onClose }) => {
    const [hours, setHours] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const { balance, refreshBalance } = useCompensatoryBalance(userId);
    const { triggerUpdate } = useCompensatoryContext();

    useEffect(() => {
        return () => {
            const backdrop = document.querySelector('.modal-backdrop');
            if (backdrop) {
                backdrop.remove();
            }
        };
    }, []);

    const handleClose = () => {
        const backdrop = document.querySelector('.modal-backdrop');
        if (backdrop) {
            backdrop.remove();
        }
        onClose();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        const minutesToPay = Math.round(parseFloat(hours) * 60);

        if (!balance || minutesToPay > balance.remaining_minutes) {
            setError('Ödeme miktarı mevcut bakiyeden fazla olamaz');
            setLoading(false);
            return;
        }

        try {
            const hourlyRate = 100;
            const paymentAmount = (minutesToPay / 60) * hourlyRate;

            await createPayment({
                user_id: userId,
                compensatory_time_id: timesheetId,
                payment_minutes: minutesToPay,
                payment_amount: paymentAmount,
                payment_date: new Date().toISOString()
            });

            setSuccess(true);
            refreshBalance();
            triggerUpdate();

            // Başarılı işlem sonrası sadece bir kere modal kapatılıyor
            setTimeout(() => {
                setSuccess(false);
                handleClose();
            }, 1500);

        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    if (!balance) {
        return null;
    }

    const maxHours = balance.remaining_minutes / 60;

    return (
        <>
            <div className="modal-header">
                <h5 className="modal-title">Serbest Zaman Ödemesi</h5>
                <button
                    type="button"
                    className="btn text-dark"
                    onClick={handleClose}
                    disabled={loading}
                >
                    <i className="fas fa-times"></i>
                </button>
            </div>

            <form onSubmit={handleSubmit}>
                <div className="modal-body">
                    <div className="mb-3">
                        <div className="d-flex justify-content-between">
                            <span>Mevcut Bakiye:</span>
                            <strong>{formatMinutesToHours(balance.remaining_minutes)}</strong>
                        </div>
                    </div>

                    {error && (
                        <div className="alert alert-danger">{error}</div>
                    )}

                    {success && (
                        <div className="alert alert-success">
                            Ödeme başarıyla gerçekleşti
                        </div>
                    )}

                    <div className="mb-3">
                        <label className="form-label">Ödenecek Süre (Saat)</label>
                        <input
                            type="number"
                            className="form-control"
                            value={hours}
                            onChange={(e) => setHours(e.target.value)}
                            min="1"
                            max={maxHours}
                            step="0.5"
                            required
                        />
                    </div>
                </div>

                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={handleClose}
                        disabled={loading}
                    >
                        İptal
                    </button>
                    <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={loading || !hours || hours < 1}
                    >
                        {loading ? (
                            <>
                                <span className="spinner-border spinner-border-sm me-2" />
                                İşleniyor...
                            </>
                        ) : 'Onayla'}
                    </button>
                </div>
            </form>
        </>
    );
};

export default CompensatoryBalancePayment;