import api from './api';

export const getCompensatoryBalance = async (userId) => {
    try {
        // Changed from /api/users/:id/compensatory_balance to /compensatory_balances/user_balance/:id
        const response = await api.get(`/compensatory_balances/user_balance/${userId}`);
        return response.data;
    } catch (error) {
        throw new Error('Serbest zaman bakiyesi alınamadı');
    }
};

export const getCompensatoryHistory = async (userId, startDate, endDate) => {
    try {
        const response = await api.get('/compensatory_times', {
            params: {
                user_id: userId,
                start_date: startDate,
                end_date: endDate
            }
        });
        return response.data;
    } catch (error) {
        throw new Error('Serbest zaman geçmişi alınamadı');
    }
};

export const convertOvertime = async (userId, overtimeData) => {
    try {
        const endpoint = overtimeData.conversionType === 'compensatory'
            ? '/compensatory_times'
            : '/compensatory_payments';

        const requestData = overtimeData.conversionType === 'compensatory'
            ? {
                user_id: userId,
                overtime_type: overtimeData.overtimeType,
                conversion_type: 'free_time',
                earned_minutes: overtimeData.overtimeMinutes,
                multiplier: overtimeData.multiplier,
                description: overtimeData.description,
                timesheet_id: overtimeData.timesheetId
            }
            : {
                compensatory_payment: {
                    user_id: userId,
                    timesheet_id: overtimeData.timesheetId,
                    payment_minutes: overtimeData.overtimeMinutes,
                    payment_amount: 1,
                    payment_date: new Date().toISOString()
                }
            };

        const response = await api.post(endpoint, requestData);
        return response.data;
    } catch (error) {
        throw new Error('Fazla mesai dönüştürme işlemi başarısız oldu');
    }
};



export const createPayment = async (paymentData) => {
    try {
        const response = await api.post('/compensatory_payments', {
            compensatory_payment: {
                user_id: paymentData.user_id,
                compensatory_time_id: paymentData.compensatory_time_id,
                payment_date: paymentData.payment_date,
                payment_minutes: paymentData.payment_minutes,
                payment_amount: paymentData.payment_amount
            }
        });
        return response.data;
    } catch (error) {
        throw new Error('Ödeme kaydı oluşturulamadı');
    }
};

export const getPayments = async (userId, status = null) => {
    try {
        const response = await api.get('/compensatory_payments', {
            params: { user_id: userId, status }
        });
        return response.data;
    } catch (error) {
        throw new Error('Ödeme kayıtları alınamadı');
    }
};

export const approvePayment = async (paymentId) => {
    try {
        const response = await api.post(`/compensatory_payments/${paymentId}/approve`);
        return response.data;
    } catch (error) {
        throw new Error('Ödeme onaylanamadı');
    }
};

export const rejectPayment = async (paymentId, rejectReason) => {
    try {
        const response = await api.post(`/compensatory_payments/${paymentId}/reject`, {
            reject_reason: rejectReason
        });
        return response.data;
    } catch (error) {
        throw new Error('Ödeme reddedilemedi');
    }
};