export const formatMinutesToHours = (minutes) => {
    if (!minutes && minutes !== 0) return '-';

    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    if (remainingMinutes === 0) {
        return `${hours}s`;
    }

    return `${hours}s ${remainingMinutes}d`;
};

export const parseHoursToMinutes = (hoursString) => {
    const parts = hoursString.split(' ');
    let totalMinutes = 0;

    parts.forEach(part => {
        if (part.endsWith('s')) {
            totalMinutes += parseInt(part) * 60;
        } else if (part.endsWith('d')) {
            totalMinutes += parseInt(part);
        }
    });

    return totalMinutes;
};

export const formatMoney = (amount) => {
    return new Intl.NumberFormat('tr-TR', {
        style: 'currency',
        currency: 'TRY'
    }).format(amount);
};

export const calculateHourlyRate = (amount, minutes) => {
    if (!amount || !minutes) return 0;
    const hours = minutes / 60;
    return amount / hours;
};

export const roundToNearestQuarter = (minutes) => {
    return Math.round(minutes / 15) * 15;
};

// İş günü kontrolü için yardımcı fonksiyon
export const isWorkDay = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6; // 0 = Pazar, 6 = Cumartesi
};

// Tarih aralığındaki iş günü sayısını hesaplama
export const getWorkDays = (startDate, endDate) => {
    let count = 0;
    const currentDate = new Date(startDate);
    const endDateObj = new Date(endDate);

    while (currentDate <= endDateObj) {
        if (isWorkDay(currentDate)) {
            count++;
        }
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return count;
};