import React from 'react';
import { format, startOfWeek, endOfWeek, eachDayOfInterval, parseISO, isWithinInterval } from 'date-fns';
import tr from 'date-fns/locale/tr';

const WeeklyPayrollTableHeader = ({ startDate, endDate, showDetails, weeks }) => {
    const formatDate = (date) => {
        if (typeof date === 'string') {
            date = parseISO(date);
        }
        return format(date, 'dd.MM', { locale: tr });
    };

    const renderBaseColumns = () => (
        <>
            <th className="sticky-col" rowSpan="3">Personel</th>
            <th className="sticky-col" rowSpan="3">TC / Sicil No</th>
            <th className="sticky-col" rowSpan="3">Şube</th>
            <th className="sticky-col" rowSpan="3">Pozisyon</th>
            <th rowSpan="3">İşe Giriş Tarihi</th>
            <th rowSpan="3">İşten Çıkış Tarihi</th>
            <th rowSpan="3">SGK Gün</th>
            <th rowSpan="3">HT FM</th>
            <th rowSpan="3">HI FM</th>
            <th rowSpan="3">HI Eksik</th>
            <th rowSpan="3">Ödendi</th>
            <th rowSpan="3">Serbest Z. Bakiye</th>
        </>
    );

    const renderSummaryColumns = () => (
        <>
            <th rowSpan="3">Toplam Çalışma</th>
            <th rowSpan="3">Toplam FM</th>
            <th rowSpan="3">Toplam Eksik</th>
            <th rowSpan="3">Genel Tatil</th>
            <th rowSpan="3">Hafta Tatili</th>
            <th rowSpan="3">Yıllık İzin</th>
            <th rowSpan="3">Ücretsiz İzin</th>
            <th rowSpan="3">Rapor</th>
            <th rowSpan="3">Devamsız</th>
        </>
    );

    const renderWeekHeaders = () => (
        weeks.map((week, weekIndex) => (
            <React.Fragment key={weekIndex}>
                <th colSpan={week.length} className="text-center header-bg-light">
                    {weekIndex + 1}. Hafta - Günlük Çalışma
                </th>
                <th colSpan="6" className="text-center header-bg-light">
                    Haftalık Özet
                </th>
            </React.Fragment>
        ))
    );

    const renderDayNames = () => (
        weeks.map((week, weekIndex) => (
            <React.Fragment key={`days-${weekIndex}`}>
                {week.map((day, dayIndex) => (
                    <th key={`header-${weekIndex}-${dayIndex}`} className="text-center">
                        {format(day, 'EEE', { locale: tr })}
                    </th>
                ))}
                <th className="text-center header-bg-light">Beklenen</th>
                <th className="text-center header-bg-light">Çalışılan</th>
                <th className="text-center header-bg-light">Hafta İçi FM</th>
                <th className="text-center header-bg-light">Hafta Tatili FM</th>
                <th className="text-center header-bg-light">Fark</th>
                <th className="text-center header-bg-light">Eksik</th>
            </React.Fragment>
        ))
    );

    const renderDates = () => (
        weeks.map((week, weekIndex) => (
            <React.Fragment key={`dates-${weekIndex}`}>
                {week.map(day => (
                    <th key={day.toISOString()} className="text-center">
                        {formatDate(day)}
                    </th>
                ))}
                <th className="text-center header-bg-light">Saat</th>
                <th className="text-center header-bg-light">Saat</th>
                <th className="text-center header-bg-light">Saat</th>
                <th className="text-center header-bg-light">Saat</th>
                <th className="text-center header-bg-light">Saat</th>
                <th className="text-center header-bg-light">Saat</th>
            </React.Fragment>
        ))
    );

    return (
        <thead>
        <tr>
            {renderBaseColumns()}
            {showDetails && renderWeekHeaders()}
            {renderSummaryColumns()}
        </tr>
        {showDetails && (
            <>
                <tr>{renderDayNames()}</tr>
                <tr>{renderDates()}</tr>
            </>
        )}
        </thead>
    );
};

export default WeeklyPayrollTableHeader;