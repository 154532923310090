// services/weeklyPayrollService.js
import api from './api';

export const fetchWeeklyPayrolls = async (params) => {
    try {
        const response = await api.get('/weekly_payrolls', { params });
        return response.data;
    } catch (error) {
        console.error('Error fetching weekly payrolls:', error);
        throw error;
    }
};

export const fetchWeeklyPayrollDetails = async (userId, startDate, endDate) => {
    try {
        const response = await api.get('/weekly_payrolls/details', {
            params: { user_id: userId, start_date: startDate, end_date: endDate }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching weekly payroll details:', error);
        throw error;
    }
};

export const generateExcelReport = (params) => {
    const searchParams = new URLSearchParams(params);
    searchParams.append('format', 'xlsx');
    return `/weekly_payrolls?${searchParams.toString()}`;
};