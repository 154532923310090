import React, { useState, useEffect } from 'react';
import { formatMinutesToHours } from '../../utilities/timeUtils';
import { convertOvertime } from '../../services/compensatoryTimeApi';
import { useCompensatoryBalance } from '../../hooks/useCompensatoryBalance';
import { useCompensatoryContext } from '../../context/CompensatoryContext';
import { useWorkTimeSummary } from '../../hooks/useWorkTimeSummary';

const OvertimeConversionModal = ({ userId, timesheetId, overtime, overtimeType, onClose }) => {
    const [conversionType, setConversionType] = useState('compensatory');
    const [multiplier, setMultiplier] = useState('1.5');
    const [description, setDescription] = useState('');
    const [conversionHours, setConversionHours] = useState(overtime / 60);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const { refreshBalance } = useCompensatoryBalance(userId);
    const { triggerUpdate } = useCompensatoryContext();
    const { updateSummary } = useWorkTimeSummary(userId);

    const multiplierOptions = [
        { value: '1', label: '1x' },
        { value: '1.25', label: '1.25x' },
        { value: '1.5', label: '1.5x' },
        { value: '2', label: '2x' },
        { value: '2.5', label: '2.5x' },
        { value: '3', label: '3x' },
        { value: '3.5', label: '3.5x' }
    ];

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'unset';
            const backdrop = document.querySelector('.modal-backdrop');
            if (backdrop) backdrop.remove();
        };
    }, []);

    const handleHoursChange = (e) => {
        const value = e.target.value;
        const maxHours = overtime / 60;

        if (value === '') {
            setConversionHours('');
            return;
        }

        const numValue = parseFloat(value);
        setConversionHours(Math.min(numValue, maxHours));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            const convertedMinutes = Math.round(conversionHours * 60);

            await convertOvertime(userId, {
                overtimeType,
                conversionType,
                overtimeMinutes: convertedMinutes,
                multiplier: parseFloat(multiplier),
                description,
                timesheetId
            });

            // Update work time summary
            await updateSummary({
                type: overtimeType,
                minutes: convertedMinutes
            });

            setSuccess(true);
            refreshBalance();
            triggerUpdate();

            setTimeout(() => {
                onClose();
            }, 2000);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="modal-content">
            <div className="modal-header bg-primary text-white">
                <h5 className="modal-title">Fazla Mesai Dönüşümü</h5>
                <button
                    type="button"
                    className="btn-close text-white"
                    onClick={onClose}
                    disabled={loading}
                ></button>
            </div>

            <form onSubmit={handleSubmit}>
                <div className="modal-body">
                    <div className="mb-4">
                        <div className="d-flex justify-content-between">
                            <span className="fw-bold">Toplam Fazla Mesai:</span>
                            <strong>{formatMinutesToHours(overtime)}</strong>
                        </div>
                    </div>

                    {error && <div className="alert alert-danger">{error}</div>}
                    {success && (
                        <div className="alert alert-success">
                            Dönüşüm başarıyla gerçekleşti
                        </div>
                    )}

                    <div className="mb-4">
                        <label className="form-label fw-bold">Dönüştürülecek Miktar (Saat)</label>
                        <input
                            type="number"
                            className="form-control"
                            value={conversionHours}
                            onChange={handleHoursChange}
                            min="0"
                            max={overtime / 60}
                            step="0.5"
                            required
                        />
                        <small className="text-muted">
                            Max: {(overtime / 60).toFixed(2)} saat
                        </small>
                    </div>

                    <div className="mb-4">
                        <label className="form-label fw-bold">Dönüşüm Tipi</label>
                        <div className="btn-group w-100" role="group">
                            <input
                                type="radio"
                                className="btn-check"
                                id="compensatory"
                                name="conversionType"
                                value="compensatory"
                                checked={conversionType === 'compensatory'}
                                onChange={(e) => setConversionType(e.target.value)}
                            />
                            <label className="btn btn-outline-primary" htmlFor="compensatory">
                                Serbest Zamana Çevir
                            </label>
                            <input
                                type="radio"
                                className="btn-check"
                                id="payment"
                                name="conversionType"
                                value="payment"
                                checked={conversionType === 'payment'}
                                onChange={(e) => setConversionType(e.target.value)}
                            />
                            <label className="btn btn-outline-primary" htmlFor="payment">
                                Ödemeye Çevir
                            </label>
                        </div>
                    </div>

                    <div className="mb-4">
                        <label className="form-label fw-bold">Çarpan</label>
                        <select
                            className="form-select"
                            value={multiplier}
                            onChange={(e) => setMultiplier(e.target.value)}
                        >
                            {multiplierOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="mb-4">
                        <label className="form-label fw-bold">Açıklama</label>
                        <textarea
                            className="form-control"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            rows="3"
                            placeholder="Dönüşüm hakkında açıklama..."
                        />
                    </div>

                    <div className="alert alert-info">
                        <strong>Dönüşüm Sonucu: </strong>
                        {formatMinutesToHours(Math.round(conversionHours * 60 * parseFloat(multiplier)))}
                    </div>
                </div>

                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={onClose}
                        disabled={loading}
                    >
                        İptal
                    </button>
                    <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={loading}
                    >
                        {loading ? (
                            <>
                                <span className="spinner-border spinner-border-sm me-2" />
                                İşleniyor...
                            </>
                        ) : (
                            'Dönüştür'
                        )}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default OvertimeConversionModal;