import React, { useState, useEffect } from 'react';
import { formatMinutesToHours } from '../../utilities/timeUtils';

const CompensatoryTimeBalance = ({ userId }) => {
    const [balance, setBalance] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchBalance();
    }, [userId]);

    const fetchBalance = async () => {
        try {
            const response = await fetch(`/api/compensatory_balance/${userId}`);
            if (!response.ok) throw new Error('Failed to fetch balance');

            const data = await response.json();
            setBalance(data);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return (
            <div className="card mb-4">
                <div className="card-body text-center">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="alert alert-danger" role="alert">
                {error}
            </div>
        );
    }

    if (!balance) return null;

    return (
        <div className="card mb-4">
            <div className="card-body">
                <div className="row">
                    <div className="col-md-4 text-center mb-3 mb-md-0">
                        <div className="text-muted mb-1">Toplam Kazanılan</div>
                        <div className="h4 text-primary mb-0">
                            {formatMinutesToHours(balance.total_earned_minutes)}
                        </div>
                    </div>

                    <div className="col-md-4 text-center mb-3 mb-md-0">
                        <div className="text-muted mb-1">Kullanılan</div>
                        <div className="h4 text-secondary mb-0">
                            {formatMinutesToHours(balance.total_used_minutes)}
                        </div>
                    </div>

                    <div className="col-md-4 text-center">
                        <div className="text-muted mb-1">Kalan</div>
                        <div className="h4 text-success mb-0">
                            {formatMinutesToHours(balance.remaining_minutes)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CompensatoryTimeBalance;