import React, { useEffect } from 'react';
import { format } from 'date-fns';
import { tr } from 'date-fns/locale';
import { createPortal } from 'react-dom';
import { formatMinutesToHours } from '../../utilities/timeUtils';
import { usePaidHistory } from '../../hooks/usePaidHistory';

export const PaymentHistoryModal = ({ userId, onClose, open }) => {
    const { history, loading, error } = usePaidHistory(userId);

    useEffect(() => {
        if (open) {
            document.body.style.overflow = 'hidden';
        }
        return () => {
            document.body.style.overflow = 'unset';
            const backdrop = document.querySelector('.modal-backdrop');
            if (backdrop) backdrop.remove();
        };
    }, [open]);

    if (!open) return null;

    const modalContent = (
        <div className="modal-wrapper" style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1050
        }}>
            <div className="modal-dialog modal-lg" style={{ margin: 0, position: 'relative', zIndex: 1055 }}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Ödeme Geçmişi</h5>
                        <button type="button" className="btn-close" onClick={onClose}/>
                    </div>

                    <div className="modal-body">
                        {loading ? (
                            <div className="text-center py-4">
                                <div className="spinner-border text-primary" role="status">
                                    <span className="visually-hidden">Yükleniyor...</span>
                                </div>
                            </div>
                        ) : error ? (
                            <div className="alert alert-danger">{error}</div>
                        ) : history.length === 0 ? (
                            <div className="text-center py-4 text-muted">Ödeme geçmişi bulunamadı.</div>
                        ) : (
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th>Tarih</th>
                                        <th className="text-center">Süre</th>
                                        <th>İşlemi Yapan</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {history.map((payment) => (
                                        <tr key={payment.id}>
                                            <td>{format(new Date(payment.payment_date), 'dd MMM yyyy', { locale: tr })}</td>
                                            <td className="text-center">{formatMinutesToHours(payment.payment_minutes)}</td>
                                            <td>{payment.processed_by || '-'}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                    <tfoot className="table-light">
                                    <tr className="fw-bold">
                                        <td>Toplam</td>
                                        <td className="text-center">
                                            {formatMinutesToHours(history.reduce((sum, p) => sum + p.payment_minutes, 0))}
                                        </td>
                                        <td></td>
                                    </tr>
                                    </tfoot>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );

    return createPortal(modalContent, document.body);
};