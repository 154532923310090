import React, { useState, useEffect } from 'react';
import { triggerSync, fetchSyncStatus } from '../../services/integrationService';

const IntegrationSync = ({ companyId }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [remainingTime, setRemainingTime] = useState(0);
    const [error, setError] = useState(null);

    useEffect(() => {
        loadInitialStatus();
    }, []);

    useEffect(() => {
        let timer;
        if (remainingTime > 0) {
            timer = setInterval(() => {
                setRemainingTime(prev => Math.max(0, prev - 1));
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [remainingTime]);

    const loadInitialStatus = async () => {
        try {
            const status = await fetchSyncStatus(companyId);
            if (!status.can_sync) {
                setRemainingTime(status.remaining_time);
            }
        } catch (err) {
            console.error('Failed to load sync status:', err);
        }
    };

    const handleSync = async () => {
        if (isLoading || remainingTime > 0) return;

        try {
            setIsLoading(true);
            setError(null);

            const response = await triggerSync(companyId);

            if (response.success) {
                // Senkronizasyon başlarsa, örnek olarak 5 dakika bekleme süresi:
                setRemainingTime(300);
            } else if (response.remainingTime) {
                setRemainingTime(response.remainingTime);
                setError(response.error);
            } else {
                setError(response.error || 'Senkronizasyon başarısız oldu');
            }
        } catch (err) {
            setError('Senkronizasyon sırasında bir hata oluştu');
            console.error('Sync error:', err);
        } finally {
            setIsLoading(false);
        }
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    return (
        <button
            onClick={handleSync}
            disabled={isLoading || remainingTime > 0}
            className={`btn btn-sm ${remainingTime > 0 || isLoading ? 'btn-secondary' : 'btn-primary'}`}
            style={{ minWidth: '80px' }}
        >
            {isLoading ? (
                <>
                    <i className="fas fa-sync-alt fa-spin mr-1" />
                    Yükleniyor...
                </>
            ) : remainingTime > 0 ? (
                formatTime(remainingTime)
            ) : (
                <>
                    <i className="fas fa-sync-alt mr-1" />
                    Sync
                </>
            )}
        </button>
    );
};

export default IntegrationSync;