import React, { useState, useEffect } from 'react';
import { getPayments } from '../services/compensatoryTimeApi';
import { useCompensatoryContext } from '../context/CompensatoryContext';

export const usePaidBalance = (userId) => {
    const [totalPaidMinutes, setTotalPaidMinutes] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { lastUpdate } = useCompensatoryContext();

    const fetchTotalPaid = async () => {
        if (!userId) return;

        try {
            setLoading(true);
            const data = await getPayments(userId);
            const totalMinutes = data.reduce((sum, payment) => sum + payment.payment_minutes, 0);
            setTotalPaidMinutes(totalMinutes);
            setError(null);
        } catch (err) {
            console.error('Error fetching paid balance:', err);
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTotalPaid();
    }, [userId, lastUpdate]);

    return {
        totalPaidMinutes,
        loading,
        error,
        refreshBalance: fetchTotalPaid
    };
};