// FoodItem.js
import React from 'react';
import './FoodItem.css';

const FoodItem = ({foodItem, onChange, onRemove}) => {
    const handleChange = (e) => {
        const {name, value} = e.target;
        onChange(foodItem.temp_id, name, value);
    };

    return (
        <div className="food-item-question-container p-3 border rounded">
            <div className="row">
                <div className="col-12">
                    <div className="form-group mb-3">
                        <label htmlFor={`name-${foodItem.temp_id}`} className="mb-2">
                            Yemek Adı
                        </label>
                        <input
                            type="text"
                            className="form-control"
                            id={`name-${foodItem.temp_id}`}
                            name="name"
                            defaultValue={foodItem.name}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <button
                        type="button"
                        className="btn btn-danger w-100"
                        onClick={onRemove}
                    >
                        Kaldır
                    </button>
                </div>
            </div>
        </div>
    );
};

export default FoodItem;