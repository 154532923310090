import { useState } from 'react';
import { updateWorkTimeSummary } from '../services/workTimeApi';

export const useWorkTimeSummary = (userId) => {
    const updateSummary = async ({ type, minutes }) => {
        try {
            return await updateWorkTimeSummary(userId, { type, minutes });
        } catch (error) {
            console.error('Error updating work time summary:', error);
            throw error;
        }
    };

    return { updateSummary };
};