import React from 'react';

const WeeklyPayrollLegend = ({ settings }) => {
    const legendItems = [
        {
            color: settings.legend_holiday_shift_color,
            label: 'Tatil Vardiyası',
            code: settings.legend_holiday_shift_label
        },
        {
            color: settings.legend_holiday_calendar_color,
            label: 'Resmi Tatil',
            code: settings.legend_holiday_calendar_label
        },
        {
            color: settings.legend_leave_color,
            label: 'İzin',
            code: settings.legend_leave_label
        },
        {
            color: settings.legend_absent_color,
            label: 'Devamsız',
            code: settings.legend_absent_label
        },
        {
            color: settings.legend_incalculable_color,
            label: 'Hesaplanamayan',
            code: settings.legend_incalculable_label
        },

        {
            color: settings.legend_saturday_entitlement_color,
            label: 'Cumartesi Hakedişi',
            code: settings.legend_saturday_entitlement_label
        }
    ];

    const getContrastColor = (hexcolor) => {
        if (!hexcolor) return '#000000';
        const r = parseInt(hexcolor.substr(1,2), 16);
        const g = parseInt(hexcolor.substr(3,2), 16);
        const b = parseInt(hexcolor.substr(5,2), 16);
        const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
        return (yiq >= 128) ? '#000000' : '#ffffff';
    };

    return (
        <div className="weekly-payroll-legend mt-4 flex flex-wrap gap-2 justify-center">
            {legendItems.map((item, index) => (
                <span
                    key={index}
                    className="inline-flex items-center px-3 py-1 rounded text-sm"
                    style={{
                        backgroundColor: item.color,
                        color: getContrastColor(item.color)
                    }}
                >
                    {item.label} {item.code && `(${item.code})`}
                </span>
            ))}
        </div>
    );
};

export default WeeklyPayrollLegend;