// WeeklyPayrollPage.jsx
import React, { useState } from 'react';
import WeeklyPayrollHeader from './WeeklyPayrollHeader';
import WeeklyPayrollFilter from './WeeklyPayrollFilter';
import WeeklyPayrollTable from './WeeklyPayrollTable';

// WeeklyPayrollPage.jsx
const WeeklyPayrollPage = ({
                               userId,
                               startDate,
                               endDate,
                               initialData,
                               companySettings,
                               branches = [],
                               userGroups = [],
                               branchGroups = [],
                               subCompanies = []
                           }) => {
    const [payrollData, setPayrollData] = useState(initialData);
    const [loading, setLoading] = useState(false);
    const [isFiltered, setIsFiltered] = useState(false);
    const [displayDates, setDisplayDates] = useState({
        startDate: startDate,
        endDate: endDate
    });
    const [showDetails, setShowDetails] = useState(false);
    const handleFilterChange = (response) => {
        console.log('Page - Received filter response:', response);

        if (response.data) {
            setPayrollData(response.data);
            setDisplayDates({
                startDate: response.startDate,
                endDate: response.endDate
            });
            setShowDetails(response.showDetails);
            console.log('Page - Updated showDetails state:', response.showDetails);

        } else {
            setPayrollData([]);
        }
    };

    return (
        <section className="section">
            <WeeklyPayrollHeader />
            <div className="section-body">
                <div className="card">
                    <div className="card-header">
                        <h4>Haftalık Puantaj</h4>
                    </div>
                    <div className="card-body">
                        <WeeklyPayrollFilter
                            userId={userId}
                            startDate={displayDates.startDate}
                            endDate={displayDates.endDate}
                            branches={branches}
                            userGroups={userGroups}
                            branchGroups={branchGroups}
                            subCompanies={subCompanies}
                            onFilter={handleFilterChange}
                            onLoadingChange={setLoading}
                            onIsFilteredChange={setIsFiltered}
                        />

                        <WeeklyPayrollTable
                            data={payrollData}
                            showDetails={showDetails}
                            startDate={displayDates.startDate}
                            endDate={displayDates.endDate}
                            companySettings={companySettings}
                            loading={loading}
                            isFiltered={isFiltered}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WeeklyPayrollPage;