import React, { useState } from 'react';
import CompensatoryTimeBalance from './CompensatoryTimeBalance';
import CompensatoryTimeHistory from './CompensatoryTimeHistory';
import ConversionDialog from './ConversionDialog';
import PaymentDialog from './PaymentDialog';

const CompensatoryTimeManager = ({ userId, weeklyCalculationId, overtimeMinutes }) => {
    const [showConvertDialog, setShowConvertDialog] = useState(false);
    const [showPaymentDialog, setShowPaymentDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleConvert = async (minutes, multiplier) => {
        setIsLoading(true);
        try {
            const response = await fetch('/api/compensatory_times', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    compensatory_time: {
                        user_id: userId,
                        weekly_calculation_id: weeklyCalculationId,
                        earned_minutes: minutes,
                        multiplier: multiplier
                    }
                })
            });

            if (!response.ok) throw new Error('Conversion failed');

            setShowConvertDialog(false);
            // Trigger balance and history refresh
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handlePayment = async (paymentDetails) => {
        setIsLoading(true);
        try {
            const response = await fetch('/api/compensatory_payments', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    compensatory_payment: {
                        ...paymentDetails,
                        user_id: userId
                    }
                })
            });

            if (!response.ok) throw new Error('Payment processing failed');

            setShowPaymentDialog(false);
            // Trigger balance refresh
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="container-fluid p-4">
            {error && (
                <div className="alert alert-danger" role="alert">
                    {error}
                </div>
            )}

            <div className="d-flex gap-3 mb-4">
                <button
                    type="button"
                    className="btn btn-primary d-flex align-items-center"
                    onClick={() => setShowConvertDialog(true)}
                    disabled={isLoading || !overtimeMinutes}
                >
                    <i className="fas fa-exchange-alt me-2"></i>
                    Serbest Zamana Çevir
                </button>

                <button
                    type="button"
                    className="btn btn-secondary d-flex align-items-center"
                    onClick={() => setShowPaymentDialog(true)}
                    disabled={isLoading}
                >
                    <i className="fas fa-dollar-sign me-2"></i>
                    Ödemeye Çevir
                </button>
            </div>

            <div className="card mb-4">
                <div className="card-body">
                    <CompensatoryTimeBalance userId={userId} />
                </div>
            </div>

            <div className="card">
                <div className="card-body">
                    <CompensatoryTimeHistory userId={userId} />
                </div>
            </div>

            <ConversionDialog
                open={showConvertDialog}
                onClose={() => setShowConvertDialog(false)}
                onConfirm={handleConvert}
                overtimeMinutes={overtimeMinutes}
                loading={isLoading}
            />

            <PaymentDialog
                open={showPaymentDialog}
                onClose={() => setShowPaymentDialog(false)}
                onConfirm={handlePayment}
                loading={isLoading}
            />
        </div>
    );
};

export default CompensatoryTimeManager;