import React, { useState, useEffect } from 'react';
import { fetchWeeklyPayrolls } from '../../services/weeklyPayrollService';

const WeeklyPayrollFilter = ({
                                 userId,
                                 startDate,
                                 endDate,
                                 branches = [],
                                 userGroups = [],
                                 branchGroups = [],
                                 subCompanies = [],
                                 onFilter = () => {},
                                 onLoadingChange = () => {},
                                 onIsFilteredChange = () => {}
                             }) => {
    const [filters, setFilters] = useState({
        firstName: '',
        lastName: '',
        email: '',
        clientId: '',
        branchId: '',
        userGroupId: '',
        branchGroupId: '',
        subCompanyId: '',
        isActive: '',
        startDate: startDate || new Date().toISOString().split('T')[0],
        endDate: endDate || new Date().toISOString().split('T')[0],
        showDetails: false
    });

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setFilters(prev => ({
            ...prev,
            startDate: startDate || prev.startDate,
            endDate: endDate || prev.endDate
        }));
    }, [startDate, endDate]);

    const handleFilterChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFilters(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSearch = async (e) => {
        e.preventDefault();
        console.log('Filter - handleSearch - showDetails value:', filters.showDetails);
        setLoading(true);
        onLoadingChange(true);
        onIsFilteredChange(true);
        setError(null);

        try {
            const searchParams = { ...filters, guid: crypto.randomUUID() };
            const responseData = await fetchWeeklyPayrolls(searchParams);
            setData(responseData);
            onFilter({
                data: responseData,
                startDate: filters.startDate,
                endDate: filters.endDate,
                showDetails: filters.showDetails
            });
            console.log('Filter - Sending to parent:', { showDetails: filters.showDetails });

        } catch (err) {
            console.error('Error fetching data:', err);
            setError(err.response?.data?.message || 'Veri yüklenirken bir hata oluştu');
            onFilter([]);
        } finally {
            setLoading(false);
            onLoadingChange(false);
        }
    };

    const handleReset = () => {
        const defaultFilters = {
            firstName: '',
            lastName: '',
            email: '',
            clientId: '',
            branchId: '',
            userGroupId: '',
            branchGroupId: '',
            subCompanyId: '',
            isActive: '',
            startDate: startDate || new Date().toISOString().split('T')[0],
            endDate: endDate || new Date().toISOString().split('T')[0],
            showDetails: false
        };

        setFilters(defaultFilters);
        onIsFilteredChange(false);
        onFilter([]);
    };

    return (
        <form onSubmit={handleSearch} className="mb-4">
            <div className="row">
                <div className="col-md-3 mb-3">
                    <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        value={filters.firstName}
                        onChange={handleFilterChange}
                        className="form-control"
                        placeholder="Ad"
                    />
                </div>
                <div className="col-md-3 mb-3">
                    <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        value={filters.lastName}
                        onChange={handleFilterChange}
                        className="form-control"
                        placeholder="Soyad"
                    />
                </div>
                <div className="col-md-3 mb-3">
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={filters.email}
                        onChange={handleFilterChange}
                        className="form-control"
                        placeholder="Email"
                    />
                </div>
                <div className="col-md-3 mb-3">
                    <input
                        type="text"
                        id="clientId"
                        name="clientId"
                        value={filters.clientId}
                        onChange={handleFilterChange}
                        className="form-control"
                        placeholder="Sicil No"
                    />
                </div>
            </div>

            <div className="row">
                {/* Organizasyon Bilgileri */}
                <div className="col-md-3 mb-3">
                    <select
                        id="branchId"
                        name="branchId"
                        value={filters.branchId}
                        onChange={handleFilterChange}
                        className="form-control"
                    >
                        <option value="">Şube Seçiniz</option>
                        {branches.map(branch => (
                            <option key={branch.id} value={branch.id}>
                                {branch.title}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="col-md-3 mb-3">
                    <select
                        id="userGroupId"
                        name="userGroupId"
                        value={filters.userGroupId}
                        onChange={handleFilterChange}
                        className="form-control"
                    >
                        <option value="">Grup Seçiniz</option>
                        {userGroups.map(group => (
                            <option key={group.id} value={group.id}>
                                {group.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="col-md-3 mb-3">
                    <select
                        id="branchGroupId"
                        name="branchGroupId"
                        value={filters.branchGroupId}
                        onChange={handleFilterChange}
                        className="form-control"
                    >
                        <option value="">Şube Grubu Seçiniz</option>
                        {branchGroups.map(group => (
                            <option key={group.id} value={group.id}>
                                {group.title}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="col-md-3 mb-3">
                    <select
                        id="subCompanyId"
                        name="subCompanyId"
                        value={filters.subCompanyId}
                        onChange={handleFilterChange}
                        className="form-control"
                    >
                        <option value="">İştirak Seçiniz</option>
                        {subCompanies.map(company => (
                            <option key={company.id} value={company.id}>
                                {company.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="row">
                <div className="col-md-3 mb-3">
                    <input
                        type="date"
                        id="startDate"
                        name="startDate"
                        value={filters.startDate}
                        onChange={handleFilterChange}
                        className="form-control"
                        max={filters.endDate}
                    />
                </div>
                <div className="col-md-3 mb-3">
                    <input
                        type="date"
                        id="endDate"
                        name="endDate"
                        value={filters.endDate}
                        onChange={handleFilterChange}
                        className="form-control"
                        min={filters.startDate}
                    />
                </div>
                <div className="col-md-3 mb-3">
                    <select
                        id="isActive"
                        name="isActive"
                        value={filters.isActive}
                        onChange={handleFilterChange}
                        className="form-control"
                    >
                        <option value="">Aktif / Pasif Personel</option>
                        <option value="true">Aktif</option>
                        <option value="false">Pasif</option>
                    </select>
                </div>
                <div className="col-md-3 mb-3">
                    <div className="form-check mt-4">
                        <input
                            type="checkbox"
                            id="showDetails"
                            name="showDetails"
                            checked={filters.showDetails}
                            onChange={handleFilterChange}
                            className="form-check-input"
                        />
                        <label className="form-check-label" htmlFor="showDetails">
                            Detaylı Görünüm
                        </label>
                    </div>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-12">
                    <button type="submit" className="btn btn-primary mr-2">
                        <i className="fas fa-search mr-1"></i>
                        Filtrele
                    </button>
                    <button
                        type="button"
                        onClick={handleReset}
                        className="btn btn-secondary"
                    >
                        <i className="fas fa-times mr-1"></i>
                        Temizle
                    </button>
                </div>
            </div>
        </form>
    );
};

export default WeeklyPayrollFilter;