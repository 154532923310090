import React, { useState } from 'react';
import { formatMinutesToHours } from '../../utilities/timeUtils';
import OvertimeConversionModal from './OvertimeConversionModal';
import { createPortal } from 'react-dom';
import { useCompensatoryContext } from '../../context/CompensatoryContext';

const OvertimeCell = ({ overtime = 0, userId, timesheetId, overtimeType }) => {
    const [showModal, setShowModal] = useState(false);
    const { lastUpdate } = useCompensatoryContext();

    const handleClick = () => {
        if (overtime > 0) {
            setShowModal(true);
        }
    };

    const handleClose = () => {
        setShowModal(false);
    };

    return (
        <>
            <div
                onClick={handleClick}
                className="d-flex align-items-center justify-content-center"
                style={{ cursor: overtime > 0 ? 'pointer' : 'default' }}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={overtime > 0 ? "Serbest zamana çevirmek için tıklayın" : ""}
            >
                <span>{overtime > 0 ? formatMinutesToHours(overtime) : '-'}</span>
                {overtime > 0 && (
                    <i className="fas fa-exchange-alt ms-1 text-primary"></i>
                )}
            </div>

            {showModal && createPortal(
                <div className="modal-wrapper" style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1050
                }}>
                    <div className="modal-dialog" style={{ margin: 0, position: 'relative', zIndex: 1055 }}>
                        <div className="modal-content">
                            <OvertimeConversionModal
                                userId={userId}
                                timesheetId={timesheetId}
                                overtime={overtime}
                                overtimeType={overtimeType}
                                onClose={handleClose}
                            />
                        </div>
                    </div>
                </div>,
                document.body
            )}
        </>
    );
};

export default OvertimeCell;