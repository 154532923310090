import React from 'react';
import {useFoodData} from "../../hooks/useFoodData";
import FoodForm from "./FoodForm";

const EditFood = ({foodId}) => {
    const {foodData, isLoading, error} = useFoodData(foodId);
    console.log("foodData", foodData);
    if (isLoading) {
        return <div>Yükleniyor...</div>;
    }

    if (error) {
        return <div>Hata: {error.message}</div>;
    }

    return (
        <div>
            {foodData && <FoodForm initialData={foodData}/>}
        </div>
    );
};

export default EditFood;