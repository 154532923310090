import React from 'react';
import { eachDayOfInterval, startOfWeek, endOfWeek, parseISO, format, addDays } from 'date-fns';
import tr from 'date-fns/locale/tr';
import WeeklyPayrollTableHeader from './WeeklyPayrollTableHeader';
import WeeklyPayrollTableBody from './WeeklyPayrollTableBody';
import WeeklyPayrollLegend from './WeeklyPayrollLegend';
import SkeletonTable from '../common/Skeleton/SkeletonTable';
import './WeeklyPayrollTable.css';

const WeeklyPayrollTable = ({
                                data = [],
                                showDetails,
                                startDate,
                                endDate,
                                companySettings,
                                loading = false,
                                isFiltered = false
                            }) => {

    console.log('Table - Received props:', { showDetails, data: data.length });


    const calculateWeeks = () => {
        console.log('Table - calculateWeeks - showDetails:', showDetails);

        if (!startDate || !endDate || !showDetails) {
            return [];
        }

        const start = parseISO(startDate);
        const end = parseISO(endDate);

        let currentWeekStart = startOfWeek(start, { weekStartsOn: 1 });
        const weeks = [];

        while (currentWeekStart <= end) {
            const weekEnd = endOfWeek(currentWeekStart, { weekStartsOn: 1 });
            const adjustedEnd = weekEnd > end ? end : weekEnd;

            const weekDays = eachDayOfInterval({
                start: currentWeekStart < start ? start : currentWeekStart,
                end: adjustedEnd
            });

            if (weekDays.length > 0) {
                weeks.push(weekDays);
            }

            currentWeekStart = addDays(weekEnd, 1);
        }

        return weeks;
    };

    const formatTableHours = (minutes) => {
        if (!minutes && minutes !== 0) return '-';

        if (companySettings?.shift_settings?.enable_rounding_for_pacs) {
            return Math.round(minutes / 60 * 100) / 100;
        }

        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;
        return `${hours}:${String(remainingMinutes).padStart(2, '0')}`;
    };

    const getStatusStyle = (timesheet, settings) => {
        if (!timesheet) return {};

        let style = {
            backgroundColor: null,
            color: '#000000',
            'data-label': null
        };

        if (timesheet.is_weekend) {
            style.backgroundColor = settings.legend_holiday_shift_color;
            style['data-label'] = settings.legend_holiday_shift_label;
        } else if (timesheet.is_absent) {
            style.backgroundColor = settings.legend_absent_color;
            style['data-label'] = settings.legend_absent_label;
        } else if (timesheet.leave_id && timesheet.leave_type) {
            style.backgroundColor = timesheet.leave_type.color_code;
            style['data-label'] = settings.legend_leave_label;
        } else if (timesheet.is_public_holiday) {
            style.backgroundColor = settings.legend_holiday_calendar_color;
            style['data-label'] = settings.legend_holiday_calendar_label;
        } else if (timesheet.is_holiday) {
            style.backgroundColor = settings.legend_holiday_shift_color;
            style['data-label'] = settings.legend_holiday_shift_label;
        } else if (timesheet.missing_hours > 0) {
            style.backgroundColor = settings.legend_missing_hours_color;
            style['data-label'] = settings.legend_missing_hours_label;
        } else if (timesheet.overtime > 0) {
            style.backgroundColor = settings.legend_overtime_color;
            style['data-label'] = settings.legend_overtime_label;
        }

        if (style.backgroundColor) {
            style.color = getContrastColor(style.backgroundColor);
        }

        return style;
    };

    const getContrastColor = (hexcolor) => {
        if (!hexcolor) return '#000000';
        const r = parseInt(hexcolor.slice(1, 3), 16);
        const g = parseInt(hexcolor.slice(3, 5), 16);
        const b = parseInt(hexcolor.slice(5, 7), 16);
        const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
        return (yiq >= 128) ? '#000000' : '#ffffff';
    };

    if (!isFiltered) {
        return (
            <div className="alert alert-info text-center p-4">
                <i className="fas fa-info-circle mr-2" />
                <p className="text-lg font-medium mb-2">
                    Lütfen arama yapabilmek için filtre kriterlerini giriniz.
                </p>
            </div>
        );
    }

    if (loading) {
        return (
            <div className="weekly-payroll-table-container">
                <SkeletonTable rows={10} columns={showDetails ? 25 : 12} />
            </div>
        );
    }

    if (isFiltered && !data?.length) {
        return (
            <div className="alert alert-warning text-center p-4">
                <i className="fas fa-exclamation-triangle mr-2" />
                Arama kriterlerinize uygun sonuç bulunamadı.
            </div>
        );
    }

    const weeks = calculateWeeks();

    return (
        <div className="weekly-payroll-table-container">
            <table className="weekly-payroll-table">
                <WeeklyPayrollTableHeader
                    weeks={weeks}
                    showDetails={showDetails}
                    startDate={startDate}
                    endDate={endDate}
                />
                <WeeklyPayrollTableBody
                    data={data}
                    weeks={weeks}
                    showDetails={showDetails}
                    formatHours={formatTableHours}
                    getStatusStyle={getStatusStyle}
                    companySettings={companySettings}
                />
            </table>
            {showDetails && data.length > 0 && (
                <WeeklyPayrollLegend
                    settings={companySettings?.shift_settings}
                />
            )}
        </div>
    );
};

export default WeeklyPayrollTable;