import api from './api';

export const updateWorkTimeSummary = async (userId, { type, minutes }) => {
    try {
        const response = await api.patch(`/work_time_summaries/${userId}`, {
            overtime_type: type,
            minutes: minutes
        });
        return response.data;
    } catch (error) {
        throw new Error('Fazla mesai özeti güncellenemedi');
    }
};