import React, {useState, useEffect} from 'react';
import AddFoodItemButton from "../FootItem/AddFoodItemButton";
import FoodItem from "../FootItem/FoodItem";
import {deleteQuestion} from "../../services/questionService";
import {addFood, deleteFoodItem, updateFood} from "../../services/foodService";

const FoodForm = ({initialData}) => {
    const [formData, setFormData] = useState({
        meal_time: '',
        fat: 0,
        protein: 0,
        carbohydrate: 0,
        calories: 0,
    });

    const [foodItems, setFoodItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (initialData) {
            setFormData({
                meal_time: initialData.meal_time || '',
                fat: initialData.fat || 0,
                protein: initialData.protein || 0,
                carbohydrate: initialData.carbohydrate || 0,
                calories: initialData.calories || 0,
                day_name: initialData.meal_time ? getDayNameInTurkish(initialData.meal_time) : '',
            });

            if (initialData.food_items && initialData.food_items.length > 0) {
                const formattedFoodItems = initialData.food_items.map(item => ({
                    id: item.id,
                    temp_id: item.id, // Edit modunda id'yi temp_id olarak da kullan
                    name: item.name || '',
                    fat: item.fat || 0,
                    protein: item.protein || 0,
                    carbohydrate: item.carbohydrate || 0,
                    calories: item.calories || 0,
                }));
                setFoodItems(formattedFoodItems);
            }
        }
    }, [initialData]);

    const getDayNameInTurkish = (dateString) => {
        const days = [
            'Pazar', 'Pazartesi', 'Salı', 'Çarşamba',
            'Perşembe', 'Cuma', 'Cumartesi'
        ];
        const date = new Date(dateString);
        return days[date.getDay()];
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const formattedData = {
            ...formData,
            food_items_attributes: foodItems.map(item => ({
                id: item.id, // Mevcut itemlar için id gönder
                name: item.name,
                fat: item.fat,
                protein: item.protein,
                carbohydrate: item.carbohydrate,
                calories: item.calories,
                ...(item.id ? { id: item.id } : {})
            }))
        };

        try {
            let response;
            if (initialData) {
                console.log("formattedData", formattedData);
                response = await updateFood(initialData.id, formattedData);
            } else {
                response = await addFood(formattedData);
            }

            if (response && response.redirect_url) {
                alert(initialData ? "Menü başarıyla güncellendi!" : "Menü başarıyla oluşturuldu!");
                window.location.href = response.redirect_url;
            } else {
                throw new Error("No response data");
            }
        } catch (error) {
            console.error("Error saving food:", error);
            alert("Menü kaydedilirken bir hata oluştu.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleFoodItemChange = (temp_id, name, value) => {
        setFoodItems(prevFoodItems =>
            prevFoodItems.map(item =>
                item.temp_id === temp_id ? {...item, [name]: value} : item
            )
        );
    };

    const handleDeleteFoodItem = async (foodItemId, tempId) => {
        try {
            if (foodItemId && initialData) {
                await deleteFoodItem(initialData.id, foodItemId);
            }

            setFoodItems(prevFoodItems => {
                const updatedFoodItem = prevFoodItems.filter(item => item.temp_id !== tempId);
                return updatedFoodItem;
            });
        } catch (error) {
            console.error("Error deleting food item:", error);
            alert("Yemek silinirken bir hata oluştu.");
        }
    };

    const handleInputChange = (e) => {
        const {name, value, type, checked} = e.target;

        setFormData((prevState) => {
            const updatedState = {
                ...prevState,
                [name]: type === 'checkbox' ? checked : value,
            };

            if (name === 'meal_time') {
                updatedState.day_name = value ? getDayNameInTurkish(value) : '';
            }

            return updatedState;
        });
    };

    const handleAddFoodItem = () => {
        setFoodItems(prevFoodItems => [
            ...prevFoodItems,
            {
                temp_id: Date.now(),
                name: '',
                fat: 0,
                protein: 0,
                carbohydrate: 0,
                calories: 0,
            }
        ]);
    };

    return (
        <div className="container-fluid px-2 px-sm-3">
            <form onSubmit={handleSubmit}>
                {/* Mevcut form yapısı aynen devam edecek */}
                <div className="row mt-3 mt-sm-5">
                    <div className="col-12 col-sm-6 mb-3 mb-sm-0">
                        <label htmlFor="meal_time">Tarih</label>
                        <input
                            type="date"
                            className="form-control"
                            id="meal_time"
                            name="meal_time"
                            value={formData.meal_time}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="col-12 col-sm-6">
                        <label htmlFor="day_name">Gün</label>
                        <input
                            type="text"
                            className="form-control"
                            id="day_name"
                            name="day_name"
                            value={formData.day_name}
                            readOnly
                        />
                    </div>
                </div>

                <div className="row mt-3 mt-sm-5">
                    <div className="col-6 col-sm-3 mb-3">
                        <div className="form-group">
                            <label htmlFor="fat">Yağ</label>
                            <input
                                type="number"
                                className="form-control"
                                id="fat"
                                name="fat"
                                value={formData.fat}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    <div className="col-6 col-sm-3 mb-3">
                        <div className="form-group">
                            <label htmlFor="protein">Protein</label>
                            <input
                                type="number"
                                className="form-control"
                                id="protein"
                                name="protein"
                                value={formData.protein}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    <div className="col-6 col-sm-3 mb-3">
                        <div className="form-group">
                            <label htmlFor="carbohydrate">Karbonhidrat</label>
                            <input
                                type="number"
                                className="form-control"
                                id="carbohydrate"
                                name="carbohydrate"
                                value={formData.carbohydrate}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    <div className="col-6 col-sm-3 mb-3">
                        <div className="form-group">
                            <label htmlFor="calories">Kalori</label>
                            <input
                                type="number"
                                className="form-control"
                                id="calories"
                                name="calories"
                                value={formData.calories}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                </div>

                <div className="row mt-3">
                    {foodItems.map((foodItem) => (
                        <div key={foodItem.temp_id} className="col-12 col-sm-6 col-lg-4 mb-3">
                            <FoodItem
                                onChange={handleFoodItemChange}
                                onRemove={() => handleDeleteFoodItem(foodItem.id, foodItem.temp_id)}
                                foodItem={foodItem}
                            />
                        </div>
                    ))}
                </div>

                <div className="row mt-3">
                    <div className="col-12">
                        <AddFoodItemButton onClick={handleAddFoodItem} title="Yeni Yemek Ekle"/>
                    </div>
                </div>

                <div className="row mt-4 mb-3">
                    <div className="col-12 text-right">
                        <button type="submit" className="btn btn-success" disabled={isLoading}>
                            {isLoading ? 'Kaydediliyor...' : (initialData ? 'Güncelle' : 'Oluştur')}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default FoodForm;