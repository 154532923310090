import React, { useState } from 'react';
import { formatMinutesToHours } from '../../utilities/timeUtils';
import CompensatoryBalancePayment from './CompensatoryBalancePayment';
import { useCompensatoryBalance } from '../../hooks/useCompensatoryBalance';
import { createPortal } from 'react-dom';

const CompensatoryBalanceCell = ({ userId, timesheetId }) => {
    const [showPaymentDialog, setShowPaymentDialog] = useState(false);
    const { balance, loading, error } = useCompensatoryBalance(userId);

    if (loading) {
        return (
            <div className="spinner-border spinner-border-sm text-primary" role="status">
                <span className="visually-hidden">Yükleniyor...</span>
            </div>
        );
    }

    if (error) {
        return <div className="text-danger">-</div>;
    }

    return (
        <>
            <div
                onClick={() => setShowPaymentDialog(true)}
                className="d-flex align-items-center justify-content-center"
                style={{ cursor: 'pointer' }}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Ödemeye çevirmek için tıklayın"
            >
                <span>{formatMinutesToHours(balance?.remaining_minutes || 0)}</span>
                <i className="fas fa-exchange-alt ms-1 text-primary"></i>
            </div>

            {showPaymentDialog && createPortal(
                <div className="modal-wrapper" style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 1050
                }}>
                    <div className="modal-dialog" style={{ margin: 0, position: 'relative', zIndex: 1055 }}>
                        <div className="modal-content">
                            <CompensatoryBalancePayment
                                userId={userId}
                                timesheetId={timesheetId}
                                onClose={() => setShowPaymentDialog(false)}
                            />
                        </div>
                    </div>
                </div>,
                document.body
            )}
        </>
    );
};

export default CompensatoryBalanceCell;