import React from 'react';
import { format } from 'date-fns';
import tr from 'date-fns/locale/tr';

const WeeklyPayrollTableHeader = ({ startDate, endDate, showDetails, weeks }) => {
    console.log('TableHeader - Received props:', { showDetails, weeksLength: weeks?.length });

    const formatDate = (date) => {
        if (typeof date === 'string') {
            date = new Date(date);
        }
        return format(date, 'dd.MM', { locale: tr });
    };

    const renderBaseColumns = () => (
        <>
            <th className="sticky-col" rowSpan={showDetails ? "3" : "1"}>Personel</th>
            <th className="sticky-col" rowSpan={showDetails ? "3" : "1"}>TC / Sicil No</th>
            <th className="sticky-col" rowSpan={showDetails ? "3" : "1"}>Şube</th>
            <th className="sticky-col" rowSpan={showDetails ? "3" : "1"}>Pozisyon</th>
            <th rowSpan={showDetails ? "3" : "1"}>İşe Giriş Tarihi</th>
            <th rowSpan={showDetails ? "3" : "1"}>İşten Çıkış Tarihi</th>
            <th rowSpan={showDetails ? "3" : "1"}>SGK Gün</th>
            <th rowSpan={showDetails ? "3" : "1"}>HT FM</th>
            <th rowSpan={showDetails ? "3" : "1"}>HI FM</th>
            <th rowSpan={showDetails ? "3" : "1"}>HI Eksik</th>
            <th rowSpan={showDetails ? "3" : "1"}>Ödendi</th>
            <th rowSpan={showDetails ? "3" : "1"}>Serbest Z. Bakiye</th>
        </>
    );

    // Temel görünüm
    if (!showDetails) {
        return (
            <thead>
            <tr>{renderBaseColumns()}</tr>
            </thead>
        );
    }

    // Detaylı görünüm
    return (
        <thead>
        <tr>
            {renderBaseColumns()}
            {weeks.map((week, weekIndex) => (
                <React.Fragment key={weekIndex}>
                    <th colSpan={week.length} className="text-center header-bg-light">
                        {weekIndex + 1}. Hafta - Günlük Çalışma
                    </th>
                    <th colSpan="6" className="text-center header-bg-light">
                        Haftalık Özet
                    </th>
                </React.Fragment>
            ))}
            <th rowSpan="3">Toplam Çalışma</th>
            <th rowSpan="3">Toplam FM</th>
            <th rowSpan="3">Toplam Eksik</th>
        </tr>
        <tr>
            {weeks.map((week, weekIndex) => (
                <React.Fragment key={`days-${weekIndex}`}>
                    {week.map((day, dayIndex) => (
                        <th key={`header-${weekIndex}-${dayIndex}`} className="text-center">
                            {format(day, 'EEE', { locale: tr })}
                        </th>
                    ))}
                    <th className="text-center header-bg-light">Beklenen</th>
                    <th className="text-center header-bg-light">Çalışılan</th>
                    <th className="text-center header-bg-light">Hafta İçi FM</th>
                    <th className="text-center header-bg-light">Hafta Tatili FM</th>
                    <th className="text-center header-bg-light">Fark</th>
                    <th className="text-center header-bg-light">Eksik</th>
                </React.Fragment>
            ))}
        </tr>
        <tr>
            {weeks.map((week, weekIndex) => (
                <React.Fragment key={`dates-${weekIndex}`}>
                    {week.map(day => (
                        <th key={day.toISOString()} className="text-center">
                            {formatDate(day)}
                        </th>
                    ))}
                    <th className="text-center header-bg-light">Saat</th>
                    <th className="text-center header-bg-light">Saat</th>
                    <th className="text-center header-bg-light">Saat</th>
                    <th className="text-center header-bg-light">Saat</th>
                    <th className="text-center header-bg-light">Saat</th>
                    <th className="text-center header-bg-light">Saat</th>
                </React.Fragment>
            ))}
        </tr>
        </thead>
    );
};

export default WeeklyPayrollTableHeader;