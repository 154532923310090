import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { formatMinutesToHours } from '../../utilities/timeUtils';

const PaymentDialog = ({
                           open,
                           onClose,
                           onConfirm,
                           loading,
                           availableMinutes = 0
                       }) => {
    const [paymentMinutes, setPaymentMinutes] = useState('');
    const [paymentAmount, setPaymentAmount] = useState('');
    const [error, setError] = useState('');

    if (!open) return null;

    const handleSubmit = (e) => {
        e.preventDefault();
        const minutes = parseInt(paymentMinutes, 10);
        const amount = parseFloat(paymentAmount);

        if (isNaN(minutes) || minutes <= 0) {
            setError('Geçerli bir süre giriniz');
            return;
        }

        if (isNaN(amount) || amount <= 0) {
            setError('Geçerli bir tutar giriniz');
            return;
        }

        if (minutes > availableMinutes) {
            setError('Kullanılabilir süreden fazla giremezsiniz');
            return;
        }

        onConfirm({
            payment_minutes: minutes,
            payment_amount: amount,
            payment_date: new Date().toISOString()
        });
    };

    const handleClose = () => {
        setPaymentMinutes('');
        setPaymentAmount('');
        setError('');
        onClose();
    };

    const calculateEstimatedAmount = () => {
        const minutes = parseInt(paymentMinutes, 10);
        if (!isNaN(minutes) && minutes > 0) {
            const hourlyRate = 100; // Bu değer dinamik olarak alınabilir
            const hours = minutes / 60;
            return (hours * hourlyRate).toFixed(2);
        }
        return 0;
    };

    return createPortal(
        <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Serbest Zamanı Ödemeye Çevir</h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={handleClose}
                            disabled={loading}
                        />
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className="modal-body">
                            <div className="mb-3">
                                <div className="d-flex justify-content-between">
                                    <span>Kullanılabilir Süre:</span>
                                    <strong>{formatMinutesToHours(availableMinutes)}</strong>
                                </div>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="minutes" className="form-label">
                                    Ödenecek Süre (Dakika)
                                </label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="minutes"
                                    value={paymentMinutes}
                                    onChange={(e) => {
                                        setPaymentMinutes(e.target.value);
                                        setPaymentAmount(calculateEstimatedAmount());
                                    }}
                                    placeholder="Örn: 120"
                                    min="0"
                                    max={availableMinutes}
                                    required
                                />
                                {paymentMinutes && (
                                    <small className="text-muted">
                                        {formatMinutesToHours(parseInt(paymentMinutes, 10))}
                                    </small>
                                )}
                            </div>

                            <div className="mb-3">
                                <label htmlFor="amount" className="form-label">
                                    Ödenecek Tutar (TL)
                                </label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="amount"
                                    step="0.01"
                                    value={paymentAmount}
                                    onChange={(e) => setPaymentAmount(e.target.value)}
                                    placeholder="Örn: 1000.00"
                                    required
                                />
                                {paymentAmount && (
                                    <small className="text-muted">
                                        Saatlik Ücret: ₺
                                        {(parseFloat(paymentAmount) / (parseInt(paymentMinutes, 10) / 60)).toFixed(2)}
                                    </small>
                                )}
                            </div>

                            {error && (
                                <div className="alert alert-danger">{error}</div>
                            )}
                        </div>

                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={handleClose}
                                disabled={loading}
                            >
                                İptal
                            </button>
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={loading}
                            >
                                {loading ? (
                                    <>
                                        <span className="spinner-border spinner-border-sm me-2" />
                                        İşleniyor...
                                    </>
                                ) : (
                                    'Onayla'
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
        </div>,
        document.body
    );
};

export default PaymentDialog;