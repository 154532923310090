import React from 'react';
import './AddFoodItemButton.css';

const AddFoodItemButton = ({ onClick, title }) => {
    return (
        <button
            type="button"
            className="btn btn-primary"
            onClick={onClick}
        >
            {title}
        </button>
    );
};

export default AddFoodItemButton;