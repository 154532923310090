import { useEffect, useState } from "react";
import {findFood} from "../services/foodService";


export const useFoodData = (foodId) => {
    const [foodData, setFoodData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const loadData = async () => {
            setIsLoading(true);
            try {
                const result = await findFood(foodId);
                setFoodData(result.data);
            } catch (error) {
                setError(error);
            } finally {
                setIsLoading(false);
            }
        };

        if (foodId) {
            loadData();
        }
    }, [foodId]);

    return { foodData, isLoading, error };
};
