import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import CompensatoryBalanceCell from "./CompensatoryBalanceCell";

const OvertimeConversionDialog = ({
                                      open,
                                      onClose,
                                      overtimeMinutes,
                                      userId,
                                      timesheetId,
                                      overtimeType,
                                      onComplete
                                  }) => {
    const [conversionType, setConversionType] = useState('compensatory');
    const [multiplier, setMultiplier] = useState('1');
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const multiplierOptions = [
        { value: '1', label: '1x' },
        { value: '1.25', label: '1.25x' },
        { value: '1.5', label: '1.5x' },
        { value: '2', label: '2x' },
        { value: '2.5', label: '2.5x' },
        { value: '3', label: '3x' },
        { value: '3.5', label: '3.5x' }
    ];

    useEffect(() => {
        if (open) {
            document.body.style.overflow = 'hidden';
        }
        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [open]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            const endpoint = conversionType === 'compensatory' ?
                '/api/compensatory_times' :
                '/api/compensatory_payments';

            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    user_id: userId,
                    timesheet_id: timesheetId,
                    overtime_minutes: overtimeMinutes,
                    multiplier: parseFloat(multiplier),
                    description,
                    overtime_type: overtimeType
                })
            });

            if (!response.ok) {
                throw new Error('İşlem başarısız oldu');
            }

            onComplete();
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    if (!open) return null;

    return createPortal(
        <div className="fixed inset-0 z-50 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen p-4">
                <div className="fixed inset-0 bg-black opacity-50"></div>

                <div className="relative bg-white rounded-lg w-full max-w-md p-6">
                    <div className="flex justify-between items-center mb-4">
                        <h3 className="text-lg font-semibold">
                            Fazla Mesai Dönüştürme
                        </h3>
                        <button onClick={onClose}>
                            <i className="fas fa-times" />
                        </button>
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className="space-y-4">
                            <div>
                                <div className="flex justify-between text-sm mb-2">
                                    <span>Fazla Mesai Süresi:</span>
                                    <strong>{overtimeMinutes} dakika</strong>
                                </div>
                            </div>

                            <div>
                                <label className="block text-sm font-medium mb-2">
                                    Dönüştürme Tipi
                                </label>
                                <div className="space-x-4">
                                    <label className="inline-flex items-center">
                                        <input
                                            type="radio"
                                            value="compensatory"
                                            checked={conversionType === 'compensatory'}
                                            onChange={e => setConversionType(e.target.value)}
                                            className="mr-2"
                                        />
                                        Serbest Zamana Çevir
                                    </label>
                                    <label className="inline-flex items-center">
                                        <input
                                            type="radio"
                                            value="payment"
                                            checked={conversionType === 'payment'}
                                            onChange={e => setConversionType(e.target.value)}
                                            className="mr-2"
                                        />
                                        Ödemeye Çevir
                                    </label>
                                </div>
                            </div>

                            <div>
                                <label className="block text-sm font-medium mb-2">
                                    Çarpan
                                </label>
                                <select
                                    value={multiplier}
                                    onChange={e => setMultiplier(e.target.value)}
                                    className="w-full border rounded px-3 py-2"
                                >
                                    {multiplierOptions.map(option => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div>
                                <label className="block text-sm font-medium mb-2">
                                    Açıklama
                                </label>
                                <textarea
                                    value={description}
                                    onChange={e => setDescription(e.target.value)}
                                    className="w-full border rounded px-3 py-2"
                                    rows="3"
                                    placeholder="Dönüşüm hakkında açıklama..."
                                />
                            </div>

                            {error && (
                                <div className="text-red-500 text-sm">
                                    {error}
                                </div>
                            )}

                            <div className="flex justify-end space-x-2 mt-4">
                                <button
                                    type="button"
                                    onClick={onClose}
                                    disabled={loading}
                                    className="px-4 py-2 text-gray-600 bg-gray-100 rounded"
                                >
                                    İptal
                                </button>
                                <button
                                    type="submit"
                                    disabled={loading}
                                    className="px-4 py-2 text-white bg-blue-500 rounded"
                                >
                                    {loading ? 'İşleniyor...' : 'Dönüştür'}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>,
        document.body
    );
};

export default OvertimeConversionDialog;