// app/javascript/context/CompensatoryContext.jsx
import React, { createContext, useContext, useState } from 'react';

const CompensatoryContext = createContext();

export const CompensatoryProvider = ({ children }) => {
    const [lastUpdate, setLastUpdate] = useState(Date.now());
    const triggerUpdate = () => setLastUpdate(Date.now());

    return (
        <CompensatoryContext.Provider value={{ lastUpdate, triggerUpdate }}>
            {children}
        </CompensatoryContext.Provider>
    );
};

export const useCompensatoryContext = () => {
    const context = useContext(CompensatoryContext);
    if (!context) {
        throw new Error('useCompensatoryContext must be used within a CompensatoryProvider');
    }
    return context;
};

export default CompensatoryContext;