// RestDayDrawer.jsx
import React, { useState, useEffect } from "react";
import AssignSection from './AssignSection';
import PeriodSection from './PeriodSection';
import LeaveTypeSection from './LeaveTypeSection';
import * as leaveService from '../../../../services/leaveService';
import ErrorModal from '../../ErrorModal';
import "./RestDayDrawer.css";
import * as shiftService from "../../../../services/shiftService";

const RestDayDrawer = ({ isOpen, onClose, users, selectedCells = [], onLeavesAdded, currentWeekDate }) => {
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [selectedDays, setSelectedDays] = useState([]);
    const [selectedLeaveType, setSelectedLeaveType] = useState(null);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');
    const [isDateRangeSelected, setIsDateRangeSelected] = useState(false);
    const [isHourlyLeave, setIsHourlyLeave] = useState(false);
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [leaveTypes, setLeaveTypes] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);



    useEffect(() => {
        fetchLeaveTypes(1, searchQuery);
    }, [searchQuery]);

    const fetchLeaveTypes = async (page, query) => {
        try {
            setIsLoading(true);
            const response = await leaveService.fetchCompanyLeaveTypes(page);
            if (page === 1) {
                setLeaveTypes(response.leave_types);
            } else {
                setLeaveTypes((prevLeaveTypes) => [...prevLeaveTypes, ...response.leave_types]);
            }
            setTotalPages(response.total_pages);
            setCurrentPage(response.current_page);
        } catch (error) {
            console.error("Error fetching leave types:", error);
            setErrorMessage("İzin türleri yüklenirken bir hata oluştu.");
            setShowError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchMoreLeaveTypes = () => {
        if (currentPage < totalPages) {
            fetchLeaveTypes(currentPage + 1, searchQuery);
        }
    };

    if (!isOpen) return null;

    const handleFormSubmit = async () => {
        if (!validateForm()) {
            return;
        }

        setIsLoading(true);
        setLoadingMessage("İzinler ekleniyor...");

        let leaveAssignments = [];

        if (selectedCells.length > 0) {
            leaveAssignments = selectedCells.map(cell => ({
                user_id: cell.employeeId,
                start_date: isHourlyLeave ? `${cell.date}T${startTime}` : cell.date,
                end_date: isHourlyLeave ? `${cell.date}T${endTime}` : cell.date,
                leave_type_id: selectedLeaveType,
                is_hourly_leave: isHourlyLeave,
                is_come_from_shift_planner: true
            }));
        } else {
            const start = new Date(startDate);
            const end = new Date(endDate);

            for (let date = start; date <= end; date.setDate(date.getDate() + 1)) {
                const dayOfWeek = date.getDay();
                const weekDays = ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cts'];

                if (selectedDays.length === 0 || selectedDays.includes(weekDays[dayOfWeek])) {
                    selectedEmployees.forEach(employeeId => {
                        leaveAssignments.push({
                            user_id: employeeId,
                            start_date: isHourlyLeave ? `${date.toISOString().split('T')[0]}T${startTime}` : date.toISOString().split('T')[0],
                            end_date: isHourlyLeave ? `${date.toISOString().split('T')[0]}T${endTime}` : date.toISOString().split('T')[0],
                            leave_type_id: selectedLeaveType,
                            is_hourly_leave: isHourlyLeave,
                            is_come_from_shift_planner: true
                        });
                    });
                }
            }
        }

        try {
            const response = await leaveService.createLeaves(leaveAssignments);
            if (response && response.leaves) {
                onLeavesAdded(response.leaves);
                setLoadingMessage("İzinler başarıyla eklendi!");
                setTimeout(() => {
                    setIsLoading(false);
                    onClose();
                }, 1500);
            } else {
                throw new Error("No leaves data in the response");
            }
        } catch (error) {
            console.error("Error creating leaves:", error);
            setLoadingMessage("Hata oluştu. Lütfen tekrar deneyin.");
            setErrorMessage(error.response?.data?.errors[0] || 'An unexpected error occurred.');
            setShowError(true);
            setIsLoading(false);
        }
    };

    const validateForm = () => {
        if (selectedCells.length === 0) {
            if (selectedEmployees.length === 0) {
                setErrorMessage("En az bir çalışan seçmelisiniz.");
                setShowError(true);
                return false;
            }
            if (!startDate || !endDate) {
                setErrorMessage("Başlangıç ve bitiş tarihlerini seçmelisiniz.");
                setShowError(true);
                return false;
            }
        }
        if (!selectedLeaveType) {
            setErrorMessage("Bir izin türü seçmelisiniz.");
            setShowError(true);
            return false;
        }
        return true;
    };

    return (
        <>
            <div className={`rest-day-drawer-overlay ${isOpen ? 'open' : ''}`} onClick={onClose}></div>
            <div className={`rest-day-drawer ${isOpen ? 'open' : ''}`}>
                <div className='rest-day-drawer-content'>
                    <div className='rest-day-drawer-header'>
                        <h2>İzin Ekle</h2>
                    </div>
                    <div className='rest-day-drawer-body'>
                        <AssignSection
                            users={users}
                            selectedEmployees={selectedEmployees}
                            setSelectedEmployees={setSelectedEmployees}
                            selectedCells={selectedCells}
                        />
                        {selectedCells.length === 0 && (
                            <PeriodSection
                                selectedDays={selectedDays}
                                setSelectedDays={setSelectedDays}
                                startDate={startDate}
                                setStartDate={setStartDate}
                                endDate={endDate}
                                setEndDate={setEndDate}
                                isDateRangeSelected={isDateRangeSelected}
                                setIsDateRangeSelected={setIsDateRangeSelected}
                                isHourlyLeave={isHourlyLeave}
                                setIsHourlyLeave={setIsHourlyLeave}
                                startTime={startTime}
                                setStartTime={setStartTime}
                                endTime={endTime}
                                setEndTime={setEndTime}
                                isRestDayDrawer={true}
                                currentWeekDate={currentWeekDate}

                            />
                        )}
                        <LeaveTypeSection
                            leaveTypes={leaveTypes}
                            selectedLeaveType={selectedLeaveType}
                            setSelectedLeaveType={setSelectedLeaveType}
                            isHourlyLeave={isHourlyLeave}
                            setIsHourlyLeave={setIsHourlyLeave}
                            selectedCells={selectedCells}
                            fetchMoreLeaveTypes={fetchMoreLeaveTypes}
                            hasMore={currentPage < totalPages}
                        />
                    </div>
                    <div className='rest-day-drawer-footer'>
                        <button
                            className='rest-day-drawer-cancel-button'
                            type='button'
                            onClick={onClose}
                            disabled={isLoading}
                        >
                            Vazgeç
                        </button>
                        <button
                            className='rest-day-drawer-add-button'
                            type='button'
                            onClick={handleFormSubmit}
                            disabled={isLoading}
                        >
                            {isLoading ? 'Ekleniyor...' : 'Ekle'}
                        </button>
                    </div>
                </div>
                {isLoading && (
                    <div className='rest-day-drawer-loading-overlay'>
                        <div className='rest-day-drawer-loading-spinner'></div>
                        <p>{loadingMessage}</p>
                    </div>
                )}
                <ErrorModal showError={showError} errorMessage={errorMessage} onClose={() => setShowError(false)}/>
            </div>
        </>
    );
};

export default RestDayDrawer;