// app/javascript/services/integrationService.js
import api from './api';

export const triggerSync = async (companyId) => {
    try {
        const response = await api.post(`/companies/${companyId}/trigger_sync`);
        console.log("Integration sync response:", response.data);
        return response.data;
    } catch (error) {
        console.error("Error triggering sync:", error);
        if (error.response?.status === 429) { // Too Many Requests
            return {
                success: false,
                error: 'Lütfen bir sonraki senkronizasyon için bekleyin',
                remainingTime: error.response.data.remaining_time
            };
        }
        throw error;
    }
};

export const fetchSyncStatus = async (companyId) => {
    try {
        const response = await api.get(`/companies/${companyId}/sync_status`);
        console.log("Sync status response:", response.data);
        return response.data;
    } catch (error) {
        console.error("Error fetching sync status:", error);
        throw error;
    }
};