import { useState, useEffect } from 'react';
import { getCompensatoryBalance } from '../services/compensatoryTimeApi';
import { useCompensatoryContext } from '../context/CompensatoryContext';
export const useCompensatoryBalance = (userId) => {
    const [balance, setBalance] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { lastUpdate } = useCompensatoryContext();
    useEffect(() => {
        fetchBalance();
    }, [userId,lastUpdate]);

    const fetchBalance = async () => {
        try {
            setLoading(true);
            setError(null);
            const data = await getCompensatoryBalance(userId);
            setBalance(data);
        } catch (err) {
            console.error('Serbest zaman bakiyesi alınamadı:', err);
            setError('Serbest zaman bakiyesi yüklenirken bir hata oluştu');
            setBalance(null);
        } finally {
            setLoading(false);
        }
    };

    const refreshBalance = () => {
        fetchBalance();
    };

    return {
        balance,
        loading,
        error,
        refreshBalance
    };
};