import React from 'react';
import { format } from 'date-fns';
import { CompensatoryBalanceCell, OvertimeCell } from '../CompensatoryTime';
import { PaidBalanceCell } from '../CompensatoryTime';
import { CompensatoryProvider } from '../../context/CompensatoryContext';
const WeeklyPayrollTableBody = ({
                                    data,
                                    weeks,
                                    showDetails,
                                    formatHours,
                                    getStatusStyle,
                                    companySettings
                                }) => {
    const findWeeklySummary = (user, weekStartDate) => {
        return user.weekly_summaries.find(summary =>
            summary.start_date === format(weekStartDate, 'yyyy-MM-dd')
        );
    };

    const calculateTotalOvertimes = (summaries) => {
        return summaries.reduce((acc, summary) => {
            return {
                weeklyOvertime: acc.weeklyOvertime + (summary.total_weekly_overtime || 0),
                holidayOvertime: acc.holidayOvertime + (summary.total_overtime || 0),
                missingHours: acc.missingHours + (summary.total_missing || 0),
            };
        }, { weeklyOvertime: 0, holidayOvertime: 0, missingHours: 0 });
    };

    const getDisplayValue = (timesheet, style) => {
        if (!timesheet) return '-';
        if (timesheet.leave_id && timesheet.leave_hours) {
            return formatHours(timesheet.leave_hours);
        }
        if (timesheet.is_weekend || timesheet.is_absent ||
            timesheet.is_public_holiday || timesheet.is_holiday) {
            return style['data-label'];
        }
        if (timesheet.actual_hours > 0) {
            return formatHours(timesheet.actual_hours);
        }
        return style['data-label'] || '-';
    };

    return (
        <CompensatoryProvider>
        <tbody>
        {data.map((user) => {
            const workTimeSummary = user.work_time_summary || {
                weekday_overtime: 0,
                weekend_overtime: 0,
                missing_minutes: 0
            };
            return (
                <tr key={user.id}>
                    <td className="sticky-col col-name">{user.full_name}</td>
                    <td className="sticky-col col-id">{user.citizenship_or_client_id || '-'}</td>
                    <td>{user.branch || '-'}</td>
                    <td>{user.job_position?.name || '-'}</td>
                    <td>{user.activation_date || '-'}</td>
                    <td>{user.expired_date || '-'}</td>
                    <td>0</td>
                    <td>
                        <OvertimeCell
                            overtime={workTimeSummary.weekend_overtime}
                            userId={user.id}
                            timesheetId={user.timesheets[0]?.id}
                            type="holiday"
                        />
                    </td>
                    <td>
                        <OvertimeCell
                            overtime={workTimeSummary.weekday_overtime}
                            userId={user.id}
                            timesheetId={user.timesheets[0]?.id}
                            type="weekday"
                        />
                    </td>
                    <td>{formatHours(workTimeSummary.missing_minutes)}</td>
                    <td className="text-center">
                        <PaidBalanceCell userId={user.id}/>
                    </td>
                    <td>
                        <CompensatoryBalanceCell
                            userId={user.id}
                            timesheetId={user.timesheets[0]?.id}
                        />
                    </td>

                    {weeks.map((week, weekIndex) => (
                        <React.Fragment key={`week-${weekIndex}`}>
                            {week.map(day => {
                                const timesheet = user.timesheets.find(t =>
                                    t.date === format(day, 'yyyy-MM-dd')
                                );
                                const style = getStatusStyle(timesheet, companySettings?.shift_settings);
                                return (
                                    <td
                                        key={format(day, 'yyyy-MM-dd')}
                                        className="text-center"
                                        style={style}
                                    >
                                        {getDisplayValue(timesheet, style)}
                                    </td>
                                );
                            })}

                            {(() => {
                                const summary = findWeeklySummary(user, week[0]);
                                return (
                                    <>
                                        <td className="text-center bg-gray-50">
                                            {formatHours(summary?.expected_hours || 450)}
                                        </td>
                                        <td className="text-center bg-gray-50">
                                            {formatHours(summary?.total_worked || 0)}
                                        </td>
                                        <td className="text-center bg-gray-50">
                                            {formatHours(summary?.total_weekly_overtime || 0)}
                                        </td>
                                        <td className="text-center bg-gray-50">
                                            {formatHours(summary?.total_overtime || 0)}
                                        </td>
                                        <td className="text-center bg-gray-50">
                                            {formatHours(summary?.total_missing || 0)}
                                        </td>
                                        <td className="text-center bg-gray-50">-</td>
                                    </>
                                );
                            })()}
                        </React.Fragment>
                    ))}

                    <td className="text-center">
                        {formatHours(user.total_actual_hours)}
                    </td>
                    <td className="text-center">
                        {formatHours(user.total_overtime)}
                    </td>
                    <td className="text-center">
                        {formatHours(user.total_missing_hours)}
                    </td>
                </tr>
            );
        })}
        </tbody>
        </CompensatoryProvider>
    );
};

export default WeeklyPayrollTableBody;