import React, { useState, useEffect } from 'react';
import WeeklyPayrollHeader from './WeeklyPayrollHeader';
import WeeklyPayrollFilter from './WeeklyPayrollFilter';
import WeeklyPayrollTable from './WeeklyPayrollTable';

const WeeklyPayrollPage = ({
                               userId,
                               startDate,
                               endDate,
                               initialData,
                               companySettings,
                               branches = [],
                               userGroups = [],
                               branchGroups = [],
                               subCompanies = []
                           }) => {
    const [payrollData, setPayrollData] = useState(initialData);
    const [loading, setLoading] = useState(false);
    const [isFiltered, setIsFiltered] = useState(false);
    const [displayDates, setDisplayDates] = useState({
        startDate: startDate,
        endDate: endDate
    });

    const handleFilterChange = (newData) => {
        console.log('Filter change received:', {
            oldDates: displayDates,
            dataLength: newData?.length
        });

        // API'den gelen response'da start_date ve end_date varsa onları kullan
        if (newData && newData.start_date && newData.end_date) {
            setDisplayDates({
                startDate: newData.start_date,
                endDate: newData.end_date
            });
        }
        setPayrollData(newData);
    };

    return (
        <section className="section">
            <WeeklyPayrollHeader />

            <div className="section-body">
                <div className="card">
                    <div className="card-header">
                        <h4>Haftalık Puantaj</h4>
                        <div className="card-header-action">
                            {/* Excel ve yazdırma butonları */}
                        </div>
                    </div>

                    <div className="card-body">
                        <WeeklyPayrollFilter
                            userId={userId}
                            startDate={startDate}
                            endDate={endDate}
                            branches={branches}
                            userGroups={userGroups}
                            branchGroups={branchGroups}
                            subCompanies={subCompanies}
                            onFilter={handleFilterChange}
                            onLoadingChange={setLoading}
                            onIsFilteredChange={setIsFiltered}
                        />

                        <WeeklyPayrollTable
                            data={payrollData}
                            showDetails={true}
                            startDate={displayDates.startDate}
                            endDate={displayDates.endDate}
                            companySettings={companySettings}
                            loading={loading}
                            isFiltered={isFiltered}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WeeklyPayrollPage;