import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { tr } from 'date-fns/locale';
import { formatMinutesToHours } from '../../utilities/timeUtils';

const CompensatoryTimeHistory = ({ userId }) => {
    const [history, setHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchHistory();
    }, [userId]);

    const fetchHistory = async () => {
        try {
            const response = await fetch(`/api/compensatory_times?user_id=${userId}`);
            if (!response.ok) throw new Error('Failed to fetch history');

            const data = await response.json();
            setHistory(data);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return (
            <div className="text-center p-4">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="alert alert-danger" role="alert">
                {error}
            </div>
        );
    }

    if (!history.length) {
        return (
            <div className="alert alert-info" role="alert">
                Henüz kayıt bulunmamaktadır.
            </div>
        );
    }

    return (
        <div className="card">
            <div className="card-header">
                <h5 className="card-title mb-0">Serbest Zaman Geçmişi</h5>
            </div>
            <div className="card-body">
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th>Tarih</th>
                            <th>İşlem Türü</th>
                            <th>Süre</th>
                            <th>Çarpan</th>
                            <th>Toplam</th>
                            <th>Durum</th>
                        </tr>
                        </thead>
                        <tbody>
                        {history.map((record) => (
                            <tr key={record.id}>
                                <td>
                                    {format(new Date(record.created_at), 'dd MMM yyyy', { locale: tr })}
                                </td>
                                <td>
                                    {record.type === 'earned' ? 'Kazanılan' : 'Kullanılan'}
                                </td>
                                <td>
                                    {formatMinutesToHours(record.earned_minutes)}
                                </td>
                                <td>
                                    {record.multiplier}x
                                </td>
                                <td>
                                    {formatMinutesToHours(record.earned_minutes * record.multiplier)}
                                </td>
                                <td>
                                        <span className={`badge ${
                                            record.status === 'approved'
                                                ? 'bg-success'
                                                : record.status === 'pending'
                                                    ? 'bg-warning'
                                                    : 'bg-danger'
                                        }`}>
                                            {record.status === 'approved' ? 'Onaylandı' :
                                                record.status === 'pending' ? 'Bekliyor' : 'Reddedildi'}
                                        </span>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default CompensatoryTimeHistory;