import { useState, useEffect } from 'react';
import { getPayments } from '../services/compensatoryTimeApi';

export const usePaidHistory = (userId) => {
    const [history, setHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchHistory = async () => {
            try {
                setLoading(true);
                const data = await getPayments(userId);
                setHistory(data);
                setError(null);
            } catch (err) {
                setError(err.message);
                setHistory([]);
            } finally {
                setLoading(false);
            }
        };

        if (userId) {
            fetchHistory();
        }
    }, [userId]);

    return { history, loading, error };
};