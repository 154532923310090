import React, { useState } from 'react';
import { usePaidBalance } from '../../hooks/usePaidBalance';
import { formatMinutesToHours } from '../../utilities/timeUtils';
import { PaymentHistoryModal } from './PaymentHistoryModal';
import { useCompensatoryContext } from '../../context/CompensatoryContext';

const PaidBalanceCell = ({ userId }) => {
    const [showHistory, setShowHistory] = useState(false);
    const { lastUpdate } = useCompensatoryContext();
    const { totalPaidMinutes, loading, error, refreshBalance } = usePaidBalance(userId);

    if (loading) {
        return (
            <div className="spinner-border spinner-border-sm text-primary" role="status">
                <span className="visually-hidden">Yükleniyor...</span>
            </div>
        );
    }

    if (error) return <div className="text-danger">-</div>;

    return (
        <>
            <div
                onClick={() => setShowHistory(true)}
                className="d-flex align-items-center justify-content-center"
                style={{ cursor: 'pointer' }}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Detayları görmek için tıklayın"
            >
                <span>{formatMinutesToHours(totalPaidMinutes)}</span>
                <i className="fas fa-history ms-1 text-primary"></i>
            </div>

            <PaymentHistoryModal
                userId={userId}
                onClose={() => setShowHistory(false)}
                open={showHistory}
            />
        </>
    );
};

export default PaidBalanceCell;