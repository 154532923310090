import axios from "axios";

const API_BASE_URL = '/foods';

export const addFood = async (foodData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}`, {food: foodData}, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.error("Error adding food:", error);
        throw error;
    }
};


export const updateFood = async (foodId, foodData) => {
    try {
        const response = await axios.put(`${API_BASE_URL}/${foodId}`, { food: foodData }, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.error("Error updating survey:", error);
        throw error;
    }
}

export const findFood = async (id) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/${id}/find_food`);
        return response.data;
    } catch (error) {
        console.error("Error finding shifts:", error);
        throw error;
    }
};

export const deleteFoodItem = async (foodId, foodItemId) => {
    try {
        const response = await axios.delete(`${API_BASE_URL}/${foodId}/food_items/${foodItemId}`);
        return response.data;
    } catch (error) {
        console.error("Error deleting food:", error);
        throw error;
    }
}