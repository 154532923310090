// app/javascript/controllers/working_model_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "select",
        "timeInputs",
        "dailyInputs",
        "weeklyInputs",
        "fullTimeDaily",
        "partTimeDaily",
        "fullTimeWeekly",
        "partTimeWeekly",
        "overtimeToleranceSection",
        "overtimeTolerance"
    ]

    connect() {
        this.toggleTimeInputs()
    }

    toggleTimeInputs() {
        const selectedModel = this.selectTarget.value

        // Tüm input alanlarını önce gizle
        this.timeInputsTarget.style.display = "none"
        this.dailyInputsTargets.forEach(el => el.style.display = "none")
        this.weeklyInputsTargets.forEach(el => el.style.display = "none")
        this.overtimeToleranceSectionTarget.style.display = "none"

        // Seçilen modele göre gerekli alanları göster
        if (selectedModel === "daily_based") {
            this.timeInputsTarget.style.display = "block"
            this.dailyInputsTargets.forEach(el => el.style.display = "block")
            this.weeklyInputsTargets.forEach(el => el.style.display = "none")
            this.overtimeToleranceSectionTarget.style.display = "block"
            this.enableInputs(["fullTimeDaily", "partTimeDaily", "overtimeTolerance"])
            this.disableInputs(["fullTimeWeekly", "partTimeWeekly"])
        }
        else if (selectedModel === "weekly_based") {
            this.timeInputsTarget.style.display = "block"
            this.dailyInputsTargets.forEach(el => el.style.display = "none")
            this.weeklyInputsTargets.forEach(el => el.style.display = "block")
            this.enableInputs(["fullTimeWeekly", "partTimeWeekly"])
            this.disableInputs(["fullTimeDaily", "partTimeDaily", "overtimeTolerance"])
        }
        else {
            // shift_based için tüm alanları devre dışı bırak
            this.disableInputs([
                "fullTimeDaily",
                "partTimeDaily",
                "fullTimeWeekly",
                "partTimeWeekly",
                "overtimeTolerance"
            ])
        }
    }

    enableInputs(targets) {
        targets.forEach(target => {
            if (this.hasTarget(target)) {
                this[`${target}Target`].disabled = false
                this[`${target}Target`].required = true
            }
        })
    }

    disableInputs(targets) {
        targets.forEach(target => {
            if (this.hasTarget(target)) {
                this[`${target}Target`].disabled = true
                this[`${target}Target`].required = false
                this[`${target}Target`].value = ""
            }
        })
    }
}