import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["select", "shiftDetection", "entranceBased"]

    connect() {
        this.toggle()
    }

    toggle() {
        const selectedValue = this.selectTarget.value

        if (selectedValue === "shift_detection") {
            this.shiftDetectionTarget.classList.remove("d-none")
            this.entranceBasedTarget.classList.add("d-none")
        } else if (selectedValue === "entrance_based") {
            this.shiftDetectionTarget.classList.add("d-none")
            this.entranceBasedTarget.classList.remove("d-none")
        } else {
            // Default case - hide both
            this.shiftDetectionTarget.classList.add("d-none")
            this.entranceBasedTarget.classList.add("d-none")
        }
    }
}